import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";
import { Event } from "@sentry/types";
import { getSentryEnvironmentClient } from "./utils/sentry";
import logger from "./utils/logger";

const enableSentry: boolean =
  process.env.ENABLE_SENTRY === "true" || process.env.NEXT_PUBLIC_ENABLE_SENTRY === "true";

const sentryDsn: string =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://c7e54b812af64f69ad9a0e83607bb3c7@o523677.ingest.sentry.io/5635925";

const sentrySampleRate: number =
  process.env.SENTRY_SAMPLE_RATE || process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE
    ? parseFloat(process.env.SENTRY_SAMPLE_RATE || process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE)
    : 0.2;

const eventExceptionMatches = (event: Event, matcher: RegExp): boolean => {
  return !!event.exception.values.find((val) => `${val.type}: ${val.value}`.match(matcher));
};

const sentryBeforeSendFilter = async (event: Event /* , hint?: EventHint */): Promise<Event | null> => {
  const result = event;

  // These are some annoying things that come up as issues that aren't.

  // SyntaxError: Unexpected token '<' from google tag manager conversion_async.js
  /* if (
    eventExceptionMatches(event, /SyntaxError: Unexpected token '<'/) &&
    result.stacktrace.toString().match(/conversion_async/)
  ) {
    return null;
  } */

  if (eventExceptionMatches(event, /TypeError: Drip_[0-9]+ is not a function/)) {
    return null;
  }

  // This filters our custom console messages out of the breadcrumbs.
  result.breadcrumbs = result.breadcrumbs.filter(
    (bc) =>
      !(
        bc.category === "console" &&
        (bc.message.match(/55555555555/) ||
          bc.message.match(/If you're here because/) ||
          bc.message.match(/By the way, make sure/) ||
          bc.message.match(/Welcome to the Fiveable console/))
      )
  );

  return result;
};

if (enableSentry) {
  logger.info(`Initializing client sentry.  (Sample Rate: ${sentrySampleRate})`);

  Sentry.init({
    enabled: enableSentry,
    dsn: sentryDsn,
    debug: (process.env.LOG_LEVEL || process.env.NEXT_PUBLIC_LOG_LEVEL) === "debug",
    beforeSend: sentryBeforeSendFilter,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [/localhost/, /fiveable\.me\//],
        idleTimeout: 2000,
      }),
    ],
    allowUrls: [/https?:\/\/([a-z.]+)?fiveable\.me/, /http:\/\/localhost:3000/],
    tracesSampleRate: sentrySampleRate,
    environment: getSentryEnvironmentClient(),
  });
}
